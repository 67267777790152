import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { Paths } from "src/utilities/constants";

export default function NotFoundPage() {
  useEffect(() => {
    navigate(Paths.LOGIN); // redirecting to home page
  }, []);

  return (
    <div>
    </div>
  );
}